import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ReactFlow, { Background, Node, Controls, Edge, MarkerType, OnConnect, OnEdgesChange, OnNodesChange, addEdge, applyEdgeChanges, applyNodeChanges, BackgroundVariant } from 'reactflow';
import 'reactflow/dist/style.css';
import { useProjectStore } from '../../../../../contexts/RootStoreProvider';
import { observer } from 'mobx-react';
import SceneNode from './SceneNode';
import { Box } from '@chakra-ui/react';
import ButtonEdge from './ButtonEdge';

const SceneBuilder = (autostich: any) => {

    const projectStore = useProjectStore();

    const [nodes, setNodes] = useState<Node[]>([]);
    const [edges, setEdges] = useState<Edge[]>(projectStore.projectUploadHotspots);

    const edgeTypes = {
        button: ButtonEdge,
    };

    const onNodesChange: OnNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    const onEdgesChange: OnEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    const onConnect: OnConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );

    const onSceneRename = (filename, sceneName) => {
        projectStore.SB_RenameScene(filename, sceneName);
    };

    const onSceneFloorplan = (filename, sceneName) => {
        // projectStore.SB_SetFloorplan(filename, sceneName);
        alert("No Implemented Yo!")
    };

    const defaultEdgeOptions = {


        markerStart: { type: MarkerType.ArrowClosed },
        markerEnd: { type: MarkerType.ArrowClosed },
        animated: true,
        style: { strokeWidth: 4, stroke: 'blue' },
        type: 'button',
    };

    useEffect(() => {
        projectStore.projectUploadHotspots = edges;
        console.log(autostich)
    }, [edges]);

    useEffect(() => {
        var sceneNodes = projectStore.projectUploadFiles.map((file, i) => ({
            id: file.File.name,
            type: 'SceneNode',
            data: {
                label: file.Name || file.File.name,
                preview: file.Preview,
                renameScene: onSceneRename,
                selectFloor: onSceneFloorplan
            },
            position: { x: i * 250, y: 0 }
        }));

        setNodes(sceneNodes as any);

        if (projectStore.projectUploadHotspots.length > 0) {
            setEdges(projectStore.projectUploadHotspots);
        } else {
            var i;
            var sceneEdges = [];
            for (i = 0; i < sceneNodes.length; i++) {

                if (sceneNodes.length > i + 1) {
                    sceneEdges.push({
                        id: sceneNodes[i].id,
                        source: sceneNodes[i].id,
                        target: sceneNodes[i + 1].id,
                        defaultEdgeOptions: { defaultEdgeOptions }
                    });
                }
            }
            setEdges(sceneEdges);
        }
    }



        , [projectStore.projectUploadFiles]);


    const nodeTypes = useMemo(() => ({ SceneNode: SceneNode }), []);
    const proOptions = { hideAttribution: true };

    return (

        <Box>

            <div style={{ height: 400 }}>
                <ReactFlow
                    nodes={nodes as any}
                    onNodesChange={onNodesChange}
                    edges={edges}
                    deleteKeyCode={["Backspace", "Delete"]}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    defaultEdgeOptions={defaultEdgeOptions}
                    proOptions={proOptions}
                    fitView
                >
                    <Background color="#d3d3d3" variant={BackgroundVariant.Cross} />
                    <Controls />
                </ReactFlow>
            </div>
        </Box>
    );

}


export default observer(SceneBuilder)