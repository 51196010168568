import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Center, Icon, useColorModeValue, SimpleGrid, GridItem, Tabs, TabList, Tab, TabPanels, TabPanel, Button, useToast, Checkbox, Stack, Flex, ButtonGroup, Spacer, Tooltip, Divider, ModalFooter, useMediaQuery, Alert, AlertIcon, } from '@chakra-ui/react'
import { useDropzone } from 'react-dropzone';
import { AiFillFileAdd } from 'react-icons/ai';
import { IFileUpload } from '../../../../models/fileUpload';
import { useProjectStore } from '../../../../contexts/RootStoreProvider';
import { observer } from 'mobx-react';
import SceneFileList from './sceneFileList';
import SceneBuilder from './sceneBuilder';

import '@pqina/pintura/pintura.css';
import { openDefaultEditor } from '@pqina/pintura';
import { IScene } from '../../../../models/scene';
import { stripUrlSasKey } from '../../../../helpers/sasKeyHelper';


const NewProjectScenes = (props) => {
  const [isBrandingChecked, setIsBrandingChecked] = useState(true);
  const [isAutoStitchChecked, setIsAutoStichChecked] = useState(true);

  const toast = useToast();
  const projectStore = useProjectStore();
  const [useMobileCheck] = useMediaQuery('(max-width: 760px)');

  const {
    isDragActive,

    getRootProps,
    getInputProps
  } = useDropzone({
    // accept: props.type === 'video' ? acceptedVideoType : acceptedImageType,
    maxSize: 20971520,
    onDrop: (acceptedFiles: any, rejectedFiles: any) => {
      var updatedFiles = transformfiles(acceptedFiles);
      projectStore.setProjectUploads(updatedFiles);
    }
  });

  const dropText = isDragActive ? 'Drop the files here ...' : 'Drag \'n\' drop scene files here, or click to select files';

  function transformfiles(acceptedFiles: any[]) {
    var transformedFiles = [] as IFileUpload[];

    acceptedFiles.map((file) => {
      var newFile = {} as IFileUpload;

      //Images Only
      //Modified
      if (file.pintura) {
        newFile = {
          File: file,
          Progress: 0,
          Status: 'Pending',
          Preview: file.preview
        } as IFileUpload;
      }

      //New
      if (file.name && !file.pintura) {
        newFile = {
          File: file,
          Progress: 0,
          Status: 'Pending',
          Preview: URL.createObjectURL(file)
        } as IFileUpload;
      }

      if (!newFile.File) {
        newFile = file;
      }

      transformedFiles.push(newFile);
    });

    return transformedFiles;
  }

  const handleSceneNameChange = (fileName: string, sceneName: string) => {
    projectStore.SB_RenameScene(fileName, sceneName);
  }

  const handleSceneRemove = (fileName: string, sceneUrl: string) => {

    projectStore.removeUploadFile(fileName, sceneUrl);
  }

  const handleSceneEdit = (file: any, index: number) => {


    editImage(file, (output) => {

      const updatedFiles = [...projectStore.projectUploadFiles];
      // replace original image with new image
      updatedFiles[index] = output;

      // revoke preview URL for old image
      if (file.preview) URL.revokeObjectURL(file.preview);

      // set new preview URL
      Object.assign(output, {
        preview: URL.createObjectURL(output)
      });

      var transformedFiles = transformfiles(updatedFiles);
      projectStore.projectUploadFiles = transformedFiles;
    })
  }

  const handleUploadScenes = () => {

    var newScenes = [];
    if (projectStore.projectUploadFiles.length > 0 && (projectStore.selectedProject !== undefined || props.currentProjectId !== undefined)) {
      projectStore.projectUploadFiles.map(async (fUpload: IFileUpload) => {

        var currentProject = projectStore.selectedProject;

        var newScene = await projectStore.uploadScene(
          fUpload,
          currentProject?.vtProjectFsid || props.currentProjectId

        ).catch((error) => {


          toast({
            title: 'Error Uploading Scene',
            description: 'There was an issue uploading Scene: ' + fUpload.Name,
            status: 'error',
            duration: 2000,
            isClosable: true,
          });

          console.log(error);


        })

        if (newScene !== undefined) {


          if (props.source === "Builder" && props.builderStore !== undefined) {
            props.builderStore.scenes.push(newScene);
            newScenes.push(newScene as IScene);
          }
          else {

            //As this is a upload and not for displaying we need to remove the Key - only for project uploader
            newScene.imageURL = stripUrlSasKey(newScene.imageURL);
            newScene.previewURL = stripUrlSasKey(newScene.previewURL);

            newScenes.push(newScene as IScene);
          }


        }

        if (newScenes.length == projectStore.projectUploadFiles.length) {

          console.log("Scenes Uploaded to Secure Storage");

          console.log(newScenes);

          // Depending on Source , Create the Tour or Just add the scenes.

          if (props.source === "Project") {

            //Sourced from a Project Create Page - therefore Create tour!

            await projectStore.createTour(
              isBrandingChecked,
              currentProject?.vtProjectFsid || props.currentProjectId,
              newScenes
            ).then(() => {

              toast({
                title: 'Project Scenes Upload',
                description: 'All Scenes have been uploaded successfully!',
                status: 'success',
                duration: 2000,
                isClosable: true,
              });

              projectStore.getProjects().finally(() => {
                //Clear the uploads, were done
                projectStore.projectUploadFiles = [];
                props.handleClose()
              })

            })
          }
          else {

            await props.builderStore.saveEditProject(false, props.builderStore.currentScene, 'All Scenes have been uploaded successfully!');
            projectStore.setIsUploading(false)
            projectStore.projectUploadFiles = [];
            props.handleClose();

          }
        }
      });
    }
    else {
      alert("Missing Project or Files")
    }
  }

  const editImage = (image, done) => {

    console.log(image);
    const imageFile = image.pintura ? image.pintura.file : image;
    const imageState = image.pintura ? image.pintura.data : {};

    const editor = openDefaultEditor({
      src: imageFile,
      imageState
    });

    editor.on('close', () => {
      // the user cancelled editing the image
    });

    editor.on('process', ({ dest, imageState }) => {
      Object.assign(dest, {
        pintura: { file: imageFile, data: imageState }
      });
      done(dest);
    });
  };

  const activeBg = useColorModeValue('gray.100', 'gray.600');

  const borderColor = useColorModeValue(
    isDragActive ? 'teal.300' : 'gray.300',
    isDragActive ? 'teal.500' : 'gray.500',
  );

  useEffect(() => { }, [projectStore.projectUploadFiles]);

  return (

    <SimpleGrid columns={{ base: 1, sm: 1, md: 1, lg: 1 }} spacing={5}>

      <GridItem colSpan={1} >

        <Center
          p={3}
          m={2}

          cursor="pointer"
          bg={isDragActive ? activeBg : 'transparent'}
          _hover={{ bg: activeBg }}
          transition="background-color 0.2s ease"
          borderRadius={4}
          border="3px dashed"
          borderColor={borderColor}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <Icon as={AiFillFileAdd} mr={2} />
          <p>{dropText}</p>
        </Center>

      </GridItem>

      <GridItem>

        <GridItem>

          <Flex minWidth='max-content' alignItems='center' gap='2'>
            <Spacer />
            {
              projectStore.projectUploadFiles.length > 0 ? (
                <ButtonGroup gap='2'>
                  {(useMobileCheck || props.source === "Builder") ? (<></>) : (

                    <>
                      <Tooltip label="Include Organisation Logo on the Base of the Tour - Turn off for Roof Projects">
                        <Checkbox defaultChecked isChecked={isBrandingChecked} onChange={(e) => setIsBrandingChecked(e.target.checked)} >Including Branding</Checkbox>
                      </Tooltip>

                      <Tooltip label="Theia 360  will try to associate scenes together - Turn off for Larger Projects">
                        <Checkbox defaultChecked isChecked={isAutoStitchChecked} disabled onChange={(e) => setIsAutoStichChecked(e.target.checked)} >Auto Stitch
                        </Checkbox>
                      </Tooltip>
                    </>

                  )
                  }
                  <Spacer />
                  <Button onClick={handleUploadScenes} isDisabled={projectStore.isUploading} loadingText={'Uploading Scenes'} isLoading={projectStore.isUploading} colorScheme={'brand'} >Upload Scenes</Button>
                </ButtonGroup>
              ) : (<></>)}

          </Flex>

        </GridItem>


        {useMobileCheck ? (<Alert status='warning'>
          <AlertIcon />
          Scene Builder not available for mobile devices
        </Alert>) : (<></>)}


        <Tabs defaultIndex={0} >
          {
            projectStore.projectUploadFiles.length > 0 ? (
              <TabList>
                <Tab>Scene List</Tab>
                {(useMobileCheck || props.source === "Builder") ? (<></>) : (<Tab>Scene Builder</Tab>)}
              </TabList>
            ) : (<></>)}
          <TabPanels>
            <TabPanel >
              <SceneFileList sceneFiles={projectStore.projectUploadFiles} handleSceneRemove={handleSceneRemove} handleSceneChange={handleSceneNameChange} handleSceneEdit={handleSceneEdit} />
            </TabPanel>

            <TabPanel>

              {(useMobileCheck || props.source === "Builder") ? (<></>) : (<SceneBuilder autostich={isAutoStitchChecked!} />)}

            </TabPanel>

          </TabPanels>
        </Tabs>
      </GridItem>

    </SimpleGrid >

  )
}

NewProjectScenes.propTypes = {
  currentProject: PropTypes.any,
  builderStore: PropTypes.any,
  currentUser: PropTypes.any,
  handleClose: PropTypes.any,
  currentProjectId: PropTypes.string,
  source: PropTypes.string
}

export default observer(NewProjectScenes)